.window {
  width: 100vw !important;
  border: none;
}

@media only screen and (min-width: 769px) {
  .smallScreen {
    display: none !important;
  }
}
