.inputField {
}

.inputField input {
  padding: 0.75em 0.5em;
}

.error {
  margin-top: 0.5rem;
}

.altLabelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 15px;
}

.flagContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.adornment {
  /* border-right: 2px solid red !important; */
  display: block;
  flex: 12;
  position: relative;
}

.adornmentDropdown {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 300px;
  min-height: 100px;
  background: #ffffff;
  /* border: 1px solid red; */
  /* padding: 5px; */
  border-radius: 5px;
  z-index: 100;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.06);
  padding-top: 5px;
}

.country {
  padding: 7px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.country img {
  margin-right: 10px;
}

.country:hover {
  background: #e4eef5;
}
