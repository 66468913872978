.container {
  max-width: 25rem;
  box-sizing: border-box !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 2rem 1rem;
  display: flex;
  min-height: calc(100vh - 4rem);
  justify-content: center;
  flex-direction: column;
}

.childrenBackground {
  background-color: white;
  min-height: 100vh !important;
}

.image {
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.heading {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--accent);
  margin-bottom: 0.5rem;
}

.subheading {
  font-size: 1rem;
  font-weight: 400;
  color: var(--accent-dark);
  margin-bottom: 3rem;
}

.actionButton {
  margin-top: 1.5rem !important;
  padding: 0.875rem 0 !important;
  font-weight: 500 !important;
  width: 100% !important;
}

@media only screen and (min-width: 769px) {
  .smallScreen {
    display: none !important;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .desktopForm {
    border: 1px solid #dde0eb;
    border-radius: 8px;
    padding: 20px;
    min-width: 360px;
  }
}
